@import url('https://fonts.googleapis.com/css?family=Lato');

@import './variables.scss';
@import './header.scss';
@import './margin.scss';
@import './form.scss';
@import './button.scss';
@import './height.scss';
@import './datepicker.scss';
@import './radioButton.scss';

html,
body,
p,
span {
  color: $main-font-color;
  font-size: $main-font-size;
  font-family: $main-font;
  padding: 0;
  margin: 0;
}

p {
  font-weight: $main-font-weight;
  line-height: $main-font-line-height;
  letter-spacing: $main-font-letter-spacing;
}

.react-datepicker__triangle::after, .react-datepicker__triangle::before {
  display: none;
}