@import './../../assets/styles/variables.scss';

.newTabLinkIcon {
  margin-left: 2px;
  transform: scale(0.75);
}

.subtitle {
  font-size: 18px;
  color: $main-title-color;
  color: $hus-primary;
  font-family: $font-lato;
  outline: none;
}