@import './../../assets/styles/variables.scss';

.newTabLinkIcon {
  margin-left: 2px;
  transform: scale(0.75);
}

.paddingBottom {
  padding-bottom: 18px;
}

.title {
  font-size: 22px;
  margin-top: 2em;
  margin-bottom: 1.5em;
  color: $hus-primary;
  color: $hus-primary;
  font-family: $font-lato;
  outline: none;
}