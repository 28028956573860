@import "../../assets/styles/variables.scss";

.tuberculosisContainer {
  display: grid;
  grid-gap: 18px;
}

.title {
  font-size: 28px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  line-height:normal;
  outline: none;
}

.content {
  font-size: 16px;
}