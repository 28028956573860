@import "./variables.scss";

/*************************************/
/*COMMON STYLES*/
/*************************************/

.layoutContainer {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column;
}

.content {
  margin-left: 8px;
  margin-right: 8px;
  flex-grow: 1;
  padding-bottom: 130px;
}

.primaryButton,
.secondaryButton {
  font-family: $font-lato;
  min-width: 310px;
  margin: 24px 12px 12px 0;
  text-align: center;
  line-height: 22px;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 700;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }
}

.primaryButton {
  background-color: $hus-primary;
  color: $main-button-font-color;
  border: 0 solid;
  transition: background 0.5s ease-in-out;

  &:focus {
    outline: 2px solid black;
    outline-offset: 2px;
  }

  &:disabled {
    background-color: #e8e8e8;
    transition: none;
    background-color: #e8e8e8;
    color: #666;
    border: 0;
    cursor: not-allowed;

    &:hover {
      background-color: #e8e8e8;
    }
  }

  &:hover {
    background-color: #527094;
  }
}

.secondaryButton {
  background-color: $hus-secondary;
  color: $hus-primary;
  border: 0.5px solid;
  transition: background 0.5s ease-in-out;

  &:focus {
    outline: 2px solid black;
    outline-offset: 2px;
  }

  &:disabled {
    background-color: #e8e8e8;
    transition: none;
    background-color: #e8e8e8;
    color: #666;
    border: 0;
    cursor: not-allowed;

    &:hover {
      background-color: #e8e8e8;
    }
  }

  &:hover {
    background-color: #e8e8e8;
  }
}

@media screen and (max-width: calc(#{$screen-sm} * 1px)) {
  .secondaryButton {
    margin: 0 12px 12px 0;
  }
}

.field-radio-group {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-left: 20px;
}

.form-group>input {
  height: 22px;
}

.form-group>select {
  height: 28px;
}

.required {
  color: red;
}

.text-danger {
  color: red
}

.radio>label>span>span {
  font-size: $form-font-size;
  font-family: $main-font;
  padding-top: 2px;
  margin-right: 10px;
}

.radio>label>span>input {
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
}

.radio>label>span {
  display: flex;
}

.checkbox>label>span {
  font-size: $form-font-size;
  font-family: $main-font;
  padding-top: 2px;
}

.checkbox>label>input {
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
}

.checkbox>label {
  display: flex;
}

.control-label {
  font-weight: 700;
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 20px;
}

option {
  font-size: $form-font-size;
  font-family: $main-font;
}

.intended {
  margin-left: 40px;
}

.tooltipFont {
  font-family: $font-lato;
}

.formHeader {
  font-size: $form-title-font-size;
  font-weight: 400;
}

.formSubHeader {
  font-size: $form-subheader-font-size;
  font-family: $font-lato;
  font-weight: 400;
  line-height: 22px;
}

.formSubText {
  font-family: $font-lato;
  font-size: $form-subtext-font-size;
  font-weight: 700;
  line-height: 22px;
}

.formLabel {
  font-weight: 700;
  font-size: $form-label-font-size;
  font-family: $font-lato;
  line-height: 24px;
  padding-right: 3px;
}

.formLabelValue {
  font-family: $font-lato;
}

.errorMsg {
  color: #e70000;
  font-size: 14px !important;

  &__lg {
    font-weight: 600;
  }
}

.text {
  &__nowrap {
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
  }
}

.title {
  font-size: 22px;
  margin-bottom: 1.5em;
  color: $main-title-color;
  font-family: $font-lato;
  outline: none;
}

@media screen and (max-width: calc(#{$screen-sm} * 1px)) {
  .text {
    &__nowrap {
      white-space: normal;
      overflow: visible;
      text-overflow: ellipsis;
    }
  }
}