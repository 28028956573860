@import '../../assets/styles/variables.scss';

.actionBox {
  border: 1px solid $hus-primary;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 25px;
  display: flex;
}

.actionBox:hover {
  outline: 5px solid $hus-primary;
}

.leftContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.rightContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.topContent {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-start;
}

.bottomContent {
  display: flex;
  flex: 1;
  margin-top: 15px;
}

.chevronIcon,
.contentTitle {
  font-size: 20px;
  color: $hus-primary;
}

.chevronIcon {
  margin-left: 20px;
}

.contentDescription {
  font-size: 16px;
  color: $hus-primary;
}

.iconContent {
  font-size: 80px;
  color: $hus-primary;
  display: flex;
  flex-direction: column-reverse;
  padding-left: 30px;
}

.note {
  margin-top: 20px;
}

.published {
  color: $message-success;
}

.unpublished {
  color: $message-error;
}

.primaryButton {
  width: 100%;
  font-weight: lighter;
}