@import '../../assets/styles/grid.scss';
@import '../../assets/styles/variables.scss';

.logoutWrapper {
  justify-content: center;
  align-items: center;
  z-index: 1201;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.2s ease;
  height: 100vh;
  overflow: scroll;
}

.logoutContent {
  position: absolute;
  left: 50%;
  top: 0px;
  background: white;
  transform: translate(-50%, 5%);
  font-size: 14px;
  line-height: 20px;
  border: 5px solid $divider-color;
  border-radius: 10px;
  box-shadow: box-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  padding: 15px;
}

.logoutContentTitle {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.logoutHelperContent {
  font-size: 16px;
}

.logoutButtons {
  justify-content: center;
}