@import "../../assets/styles/variables.scss";

.marginTopOnBehalf {
  margin-top: 44px;
}

.headerContainer {
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: calc(#{$screen-md} * 1px)) {
  .headerContainer {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.headerContent {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.headerTitle {
    font-weight: 700;
    font-size: $header-font-size;
    margin: 0;
}

.headerButtons {
  display: flex;
  justify-content: space-between;
}

.dividerHeader {
    border-top: 5px solid $divider-color;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    margin: 0px;
}

.headerLink, .headerLink:focus, .headerLink:active, .headerLink:hover {
  text-decoration: none;
  color: inherit;
}