.label,
.itemLabel {
  line-height: 22px;
  font-size: 14px;
  margin-left: 1px !important;
}

.fontWeight {
  font-weight: bold;
}

.required::before {
  content: '*';
  color: red;
}

.item {
  margin-right: 15px;
}

fieldset {
  border: none;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
}

.fieldWrapper {
  display: flex;
  margin-bottom: 15px;
}

.infoButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-top: -2px;
    margin-left: 4px;
}
  
.infoButton:hover {
    opacity: 0.8;
}

.infoIcon {
    width: 25px;
    height: 25px;
}

.toolTip {
    width: 30px;
    height: 30px;
}

.legendWrapper {
    display: flex;
    flex-direction: row;
}

.errorMsg {
    color: #e70000;
    font-size: 14px;
}
