@import "./variables.scss";

@mixin basicStyles {
    font-family: $font-lato;
    min-width: 310px;
    margin: 24px 12px 12px 0;
    text-align: center;
    line-height: 22px;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 700;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }
}

.button {
    &__primary {
        @include basicStyles();
        background-color: $hus-primary;
        color: $main-button-font-color;
        border: 0 solid;
        transition: background 0.5s ease-in-out;

        &:focus {
            outline: 2px solid black;
            outline-offset: 2px;
        }
        
        &:disabled {
            background-color: #e8e8e8;
            transition: none;
            background-color: #e8e8e8;
            color: #666;
            border: 0;
            cursor: not-allowed;
        
            &:hover {
            background-color: #e8e8e8;
            }
        }
        
        &:hover {
            background-color: #527094;
        }
    }

    &__secondary {
        @include basicStyles();
        background-color: $hus-secondary;
        color: $hus-primary;
        border: 0.5px solid;
        transition: background 0.5s ease-in-out;
        
        &:focus {
            outline: 2px solid black;
            outline-offset: 2px;
        }
        
        &:disabled {
            background-color: #e8e8e8;
            transition: none;
            background-color: #e8e8e8;
            color: #666;
            border: 0;
            cursor: not-allowed;
        
            &:hover {
            background-color: #e8e8e8;
            }
        }
        
        &:hover {
            background-color: #e8e8e8;
        }
    }
}

@media screen and (max-width: calc(#{$screen-sm} * 1px)) {
    .button {
        &__secondary {
            margin: 0 12px 12px 0;
        }
    }
}