@import "./variables.scss";

.form {
    &__label {
        font-weight: 700;
        font-size: $form-label-font-size;
        font-family: $font-lato;
        line-height: 24px;
        padding-right: 3px;
        &--input {
            font-family: $font-lato;
            font-size: $form-font-size;
        }
    }
}

div.form {
    &__error {
        color: #e70000;
        font-size: 14px;

        &--lg {
            color: #e70000;
            font-size: 14px;
            font-weight: 600;
        }
    }
  
}
