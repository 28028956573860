@import './../../assets/styles/variables.scss';

.contentText {
  color: $main-font-color;
  margin: 15px;
  font-size: 18px;
}

.contentTextBlock {
  margin-block-start: 1em;
  margin-block-end: 1em;
  outline: none;
}
