@import "../../assets/styles/variables.scss";

.modal {
  justify-content: center;
  align-items: center;
  z-index: 1201;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(229, 237, 243, 0.6);
  opacity: 1;
  transition: opacity 0.2s ease;
  height: 100vh;
  overflow: scroll;
}

.modalWrapper {
  background: white;
  top: 35%; 
  position: absolute;
  border: 2px solid #80D6D8;
  left: 50%;
  border-radius: 7px;
  transform: translate(-50%, 5%);
  padding: 15px;
  overflow: hidden;  
  width: 600px !important;
}


.buttonWidth {
  min-width: 130px;
}

.infoIcon {
  position: relative;
  width: 210px;
  height: 210px;
  left: -40px;
  top: 15px;
  rotate: 20deg;
  color: #BFEAEC;
}