@import "../../assets/styles/variables.scss";

.frontPageContainer {
  display: grid;
  grid-gap: 20px;
  margin-top: 20px !important;

  a, a:visited, a:active {
    color: $main-link-color;
  }
}

.frontPageButton {
  margin: 0 auto;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 100%;
}

.centeredLabel {
  margin: 0 auto;
}

@media screen and (max-width: 399px) {
  .labelSubText {
    width: 100%;
    display: grid;
  }
}

.newTabLinkIcon {
  margin-left: 2px;
  transform: scale(0.75);
}