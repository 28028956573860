@import "./variables.scss";

.datepicker {
    > div {
        height: 35px;
    }
    &__label {
        margin-bottom: 2px;
    }
}

.datepicker input {
    height: 0;
    font-size: $form-font-size;
}