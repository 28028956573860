@import '../../assets/styles/variables.scss';

.titlePadding {
  padding-top: 24px;
}

.valueText {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.11px;
}