/**********************************/
/*VARIABLES*/
/*********************************/
$screen-sm: 576;
$screen-md: 780;
$screen-lg: 992;
$screen-xl: 1200;

$hus-primary: #1B4A83;
$hus-secondary: #FFF;
$divider-color: #00ADB1;
$message-success: #01ac13;
$message-error: #ff0000;

$font-lato: 'Lato', sans-serif;
$main-font: $font-lato;
$main-font-size: 16px;
$main-font-color: black;
$main-font-letter-spacing: -0.11px;
$main-font-line-height: 22px;
$main-font-weight: 400;
$main-link-color: #1B4A83;
$main-title-color: #004B87;

$main-button-radius: 5px;
$main-button-font-color: #FFF;

$header-font-size: 30px;


$language-font-size: 18px;

$form-title-font-size: 28px;
$form-label-font-size: 18px;
$form-subheader-font-size: 18px;
$form-subtext-font-size: 16px;
$form-intertitle-font-size: 20px;
$form-label-font-size: 14px;
$form-font-size: 14px;

$tooltip-bg-color: #DFFBFF;