@import '../../assets/styles/variables.scss';

.accordionItem {
    margin: 2rem auto;
    background-color: tran;
}

.accordionTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    border: 3px $hus-primary;
    border-style: solid none;
}

.title {
    padding-left: 30px;
    font-weight: bold;
    color: $hus-primary;
    font-size: 16px;
}


.accordionTitle,
.accordionContent {
    padding: 1rem;
    font-size: 14px;
}


.divider {
    color: $hus-primary;
    border-top: 3px solid $hus-primary;
}

.accordionArrow {
    color: $hus-primary;
}