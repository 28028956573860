@import "../../assets/styles/variables.scss";

.hidden {
    display: none !important;
}

.logout {
    display: flex;

}

.logout button {
    color: $hus-primary;
    font-size: 18px;
    border: none;
    background-color: #ffffff;
    cursor: pointer;
    text-align: right;
    width: max-content;
    margin-left: 12px;
}