@import "../../assets/styles/variables.scss";

.wrapper {
  display: flex;
  justify-content: center;
  font-family: $font-lato;
}

.breadcrumSeparator {
  position: relative;
}

.breadcrumSeparator hr {
  color: #007e93;
  border-top: 2px solid #007e93;
  position: absolute;
  top: 15px;
  left: -21px;
  right: 0px;
}

@media screen and (max-width: calc(#{$screen-md} * 1px)) {
  .wrapper {
    justify-content: center;
  }
}
