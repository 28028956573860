.container {
  font-size: 16px;
}

.titleWrapper {
  font-weight: bold;
}

.valueWrapper {
  text-transform: uppercase;
}
