.checkboxContainer {
  display: flex;
  align-items: center;

  .checkbox {
    width: 0px;
    height: 0px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    border-radius: 3px;
    margin: 2px 29px 29px 0;
  }

  [role="checkbox"] {
    padding: 4px 8px;
    cursor: pointer;
  }

  [role="checkbox"]::before {
    position: relative;
    width: 20px;
    height: 20px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' zoomAndPan='magnify' viewBox='0 0 30 22' height='18' preserveAspectRatio='xMidYMid meet' version='1.0'%3E%3Cdefs%3E%3CclipPath id='id1'%3E%3Cpath d='M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 ' clip-rule='nonzero'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23id1)'%3E%3Cpath fill='rgb(13.729858%25, 12.159729%25, 12.548828%25)' d='M0.56072,0 L3.2,0 L3.2,3.2 L0.56072,3.2 L0.09426,2.07386 C-0.03142,1.77045 -0.03142,1.42955 0.09426,1.12614 L0.56072,0 Z' fill-opacity='1' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    border-radius: 3px;
  }

  [role="checkbox"][aria-checked="true"]::before {
    position: relative;
    width: 20px;
    height: 20px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' zoomAndPan='magnify' viewBox='0 0 30 22' height='18' preserveAspectRatio='xMidYMid meet' version='1.0'%3E%3Cdefs%3E%3CclipPath id='id1'%3E%3Cpath d='M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 ' clip-rule='nonzero'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23id1)'%3E%3Cpath fill='rgb(13.729858%25, 12.159729%25, 12.548828%25)' d='M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 ' fill-opacity='1' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    border-radius: 3px;
  }

  [role="checkbox"]:focus,
  [role="checkbox"]:hover {
    padding: 2px 6px;
    border: 2px solid #005a9c;
    border-radius: 5px;
    background-color: #def;
  }

  [role="checkbox"]:hover {
    cursor: pointer;
  }
}
