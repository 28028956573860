.margin {
    &__top-pos {
        &--5 {
            margin-top: 5px;
        }
        &--10 {
            margin-top: 10px;
        }
        &--50 {
            margin-top: 50px;
        }
        &--17 {
            margin-top: 17px;
        }
        &--30 {
            margin-top: 30px;
        }
    }
    &__left-pos {
        &--40 {
            margin-left: 40px;
        }
    }
    &__left-pos {
        &--5-mobile {
            margin-left: 5px;
        }
    }
    &__right-pos {
        &--5-mobile {
            margin-right: 5px;
        }
        &--30 {
            margin-right: 30px;
        }
    }
    &__bottom-pos {
        &--5 {
            margin-bottom: 5px;
        }
        &--15 {
            margin-bottom: 15px;
        }
        &--18 {
            margin-bottom: 18px;
        }
        &--60 {
            margin-bottom: 60px;
        }
        &--50 {
            margin-bottom: 50px;
        }
    }
}

@media screen and (max-width: calc(#{$screen-sm * 1px})) {
    .margin {
        &__right-pos {
            &--5-mobile {
                margin-right: 0px;
            }
        }
    }
}