@import "./variables.scss";

@mixin baseHeader() {
  //Reset browser defaults
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 12px 0 12px 0;
}

.form-header {
  @include baseHeader();
  font-size: $form-title-font-size;
  font-weight: 400;
  outline: none;

  &__sub {
    @include baseHeader();
    font-size: $form-subheader-font-size;
    font-weight: 400;
    line-height: 42px;


    &--small {
      @include baseHeader();
      font-size: $form-subtext-font-size;
      font-weight: 700;
      line-height: 22px;
    }

    &--small-bold {
      @include baseHeader();
      font-weight: 700;
      font-size: $form-intertitle-font-size;
      line-height: 24px;
      padding: 16px 0 2px 0;
    }
  }
}
