.radiobutton {
    legend {
        margin-bottom: 5px;
    }
    input {
        width: 20px;
        height: 20px;
    }
    label {
        margin-left: 5px;
    }
    &__row {
        display: flex;
        flex-direction: row;
    }
}