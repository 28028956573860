.pageContainer {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px
}

.title {
    font-size:x-large;
    padding-bottom: 10px;
    outline: none;
}

.content {
    font-size: large;
    padding-bottom: 10px;
}

.actionBox {
    padding-bottom: 10px;
}