@import "../../assets/styles/variables.scss";

.actOnBehalfContainer {
  background-color: #FDF0F6;
  border-bottom: 1px solid #FADBE9;
  height: 38px;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 9999;
}

.actOnBehalfContent {
  margin-left: 40px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: $hus-primary;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

@media screen and (max-width: $screen-lg * 1px) {
  .actOnBehalfContent {
    margin-left: 8px;
  }
}

@media screen and (max-width: 399px) {
  .actOnBehalfContent {
    font-size: 14px;
  }
}

.underlinedName {
  padding-bottom: 1px;
  border-bottom: 1px solid $hus-primary;
  font-weight: 700;
}