@import "../../assets/styles/variables.scss";

.footerContainer {
    background-color: $hus-primary;
    margin: 0px;
    text-align: center;
    padding-bottom: 35px;
}

.dividerFooter {
    border-bottom: 5px solid $divider-color;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    margin: 0px;
}

.bottomLinksContainer {
    font-size: 14px;

    a {
        text-decoration: none;
        color: white;
    }

    p {
        font-size: 14px;
        color: white;
    }

    padding-top: 30px;
    margin-bottom: 20px;
}

.separator {
    border-left: 2px solid white;
    margin-left: 10px;
    margin-right: 10px;
}