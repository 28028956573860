@import "../../assets/styles/variables.scss";

.breadcrumsWrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  display: flex;
}

.breadcrumLeft {
  border-left: 1px solid #007e93;
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
  border-top: 1px solid #007e93;
  border-bottom: 1px solid #007e93;
}

.number {
  border-right: 1px solid #007e93;
  border-radius: 27px;
  width: 27px;
  height: 27px;
  color: #007e93;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.selected {
  background: #007e93;
  color: white;
  margin-left: -2px;
}

.breadcrumMiddle {
  border-top: 1px solid #007e93;
  border-bottom: 1px solid #007e93;
  display: flex;
}

.data {
  border-top: 1px solid #007e93;
  border-bottom: 1px solid #007e93;
  display: table-cell;
  padding-left: 5px;
  color: #007e93;
  margin-right: -15px;
  z-index: 1;
  margin: 0 auto;
  padding-top: 4px;
}

.breadcrumRight {
  border-right: 1px solid #007e93;
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  padding-right: 30px;
  border-top: 1px solid #007e93;
  border-bottom: 1px solid #007e93;
}

@media screen and (max-width: calc(#{$screen-lg * 1px})) {
  .breadcrumMiddle,
  .data,
  .breadcrumRight {
    display: none;
  }

  .breadcrumsWrapper {
    padding-right: 16px;
    display: flex;
  }

  .breadcrumLeft {
    border-top: none;
    border-bottom: none;
  }

  .number {
    border-top: 1px solid #007e93;
    border-bottom: 1px solid #007e93;
  }
  .clickable:hover > div {
    border-radius: 27px;
    width: 80%;
  }
}
