@import "../../assets/styles/variables.scss";

.headerLanguage {
  text-align: right;
  margin-top: auto;
  margin-bottom: auto;

  a, a:visited, a:active {
    color: $main-link-color;
  }
}

.languageSelectors {
  color: $hus-primary;
  font-size: $language-font-size;
  text-decoration: none;
  font-weight: 700;
  font-family: $font-lato;
}
