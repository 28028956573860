.header h2 {
  font-size: 20px;
  line-height: 24px;
}

.hidden {
  display: none;
}

.itemContainer {
  margin-bottom: 20px;
}
