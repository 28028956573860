.required {
  font-weight: bold;
}

.asterisk {
  color: red;
}

.dropdownWrapper {
  width: 100%;
  margin-bottom: 20px;
}

div[class*='-control'] {
  border-radius: 0px;
}

div[class*='-MenuList'] {
  background-color: #ffffff;
}

div[class*='-menu'] > div {
  background-color: #ffffff;
}

div[class*='-menu-outer'] {
  background-color: #ffffff;
}

.Select-menu-outer {
  z-index: 100 !important;
}

.errorMsg {
  color: #e70000;

  &__lg {
    font-weight: 600;
  }
}
